<template>
  <div class="conponent-info-button-toltip" v-if="item != null">
    <b-button v-b-tooltip.hover :title="item" class="info-icon">
      <font-awesome-icon icon="info" />
    </b-button>
  </div>
</template>

<script>
export default {
  props: {
    item: { required: true }
  }
};
</script>

<style lang="scss" scoped>
.conponent-info-button-toltip {
  text-align: center;
  margin-right: 3px;
  .info-icon {
    color: #fff;
    background-color: #9799d6;
    border-color: #6569d0;
    padding: 0px;
    font-size: 8px;
    width: 14px;
    height: 14px;
    border: 1px solid #9799d6;
    box-shadow: 0px 3px 6px #0e0d0d1f;
    border-radius: 50px;
  }
}
</style>
